export interface IMessage<
  K extends keyof IMessagePayloadTypes = keyof IMessagePayloadTypes,
> {
  type: K;
  payload: IMessagePayloadTypes[K];
}
export type MessageHandler<T> = (payload: T) => void;

export const MessageType = {
  ROUTER_CHANGE: "routerChange",
  RDMP_INIT: "rdmpInit",
  RDMP_SCROLL: "rdmpScroll",
  RDMP_RESIZE: "rdmpResize",
  RDMP_LOADED: "rdmpLoaded",
  RDMP_PARENT_URL_RESPONSE: "rdmpParentUrlResponse",
  SET_USER_INFO: "setUserInfo",
} as const;

type Nil = {};
export interface IMessagePayloadTypes {
  [MessageType.ROUTER_CHANGE]: string;
  [MessageType.RDMP_INIT]: {
    pathname: string;
    roadmapData: IRoadmapData;
    options: ILoadOptions;
  };
  [MessageType.RDMP_SCROLL]: number;
  [MessageType.RDMP_LOADED]: Nil;
  [MessageType.RDMP_RESIZE]: number;
  [MessageType.RDMP_PARENT_URL_RESPONSE]: string | Nil;
  [MessageType.SET_USER_INFO]: {
    email: string;
    first: string;
    last: string;
  };
}
