import * as amplitude from "@amplitude/analytics-browser";

import { i18next } from "./i18n";

export class Data {
  private async makeRequest(
    method: string,
    path: string,
    data: any,
    success: (body: any) => void,
    error: (err: any, statusCode?: number) => void
  ): Promise<void> {
    const BASE_API = API_SERVER_URL;

    const url: string = BASE_API + path;

    const options: object = {
      method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      cache: "no-cache",
      body: data ? JSON.stringify(data) : null,
    };

    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 30000);

      const resp = await fetch(url, {
        ...options,
        signal: controller.signal,
      });

      clearTimeout(timeoutId);

      if (resp.status === 404 && method === "GET") {
        return success(null);
      }

      if (resp.status > 299) {
        try {
          const result = await resp.json();
          error(result, resp.status);
          console.error("error response:", result);
        } catch (ex) {
          error(ex, resp.status);
        }
        return;
      }

      try {
        const isTextResponse = path.includes(".json") || path.includes(".html");
        const result = await (isTextResponse ? resp.text() : resp.json());
        success(result);
      } catch (ex) {
        error(ex);
      }
    } catch (ex) {
      if (ex.name === "AbortError") {
        error({
          message: i18next.t("Request timeout, please try again later"),
        });
        return;
      }
      error(ex);
    }
  }

  getRoadmap(id: string) {
    return new Promise((resolve, reject) => {
      this.makeRequest(
        "GET",
        `/v1/roadmaps/${id}/public`,
        null,
        resolve,
        reject
      );
    });
  }

  follow(token: string, payload: IPayloadUserInfo & { id: string }) {
    return new Promise((resolve, reject) => {
      this.makeRequest(
        "POST",
        `/v1/subscribers/${token}`,
        payload,
        resolve,
        reject
      );
    });
  }

  engage(token: string, payload: IPayloadUserInfo & { isClap: boolean }) {
    return new Promise((resolve, reject) => {
      this.makeRequest(
        "PUT",
        `/v1/subscribers/${token}`,
        payload,
        resolve,
        reject
      );
    });
  }

  open(token: string, payload: IPayloadUserInfo) {
    return new Promise((resolve, reject) => {
      this.makeRequest(
        "POST",
        `/v1/subscribers/click/${token}`,
        payload,
        resolve,
        reject
      );
    });
  }

  submitComment(token: string, payload: IPayloadUserInfo & { body: string }) {
    return new Promise<IComment>((resolve, reject) => {
      this.makeRequest(
        "POST",
        `/v1/subscribers/comment/${token}`,
        payload,
        resolve,
        reject
      );
    });
  }

  getSubscriptions(id: string, email: string) {
    const payload = {
      email,
      roadmapId: id,
    };

    return new Promise<string[]>((resolve, reject) => {
      this.makeRequest(
        "POST",
        "/v1/roadmaps/subscribed",
        payload,
        resolve,
        reject
      );
    });
  }

  getInteraction(id: string, email: string) {
    const payload = {
      email,
      roadmapId: id,
    };

    return new Promise<string[]>((resolve, reject) => {
      this.makeRequest(
        "POST",
        "/v1/roadmaps/interaction",
        payload,
        resolve,
        reject
      );
    });
  }

  submitFeedback(payload: {
    user: IPayloadUserInfo;
    id: string;
    title: string;
    idea: string;
  }) {
    payload.user.revenue = payload.user.revenue
      ? parseInt(payload.user.revenue).toString()
      : null;

    return new Promise((resolve, reject) => {
      this.makeRequest(
        "POST",
        "/v1/roadmaps/feedback",
        payload,
        resolve,
        reject
      );
    });
  }
  widgetidentify(roadmapId: string, data: any): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.makeRequest(
        "POST",
        `/roadmaps/${roadmapId}/widgetidentify`,
        data,
        resolve,
        reject
      );
    }).then((appUserId) => {
      sessionStorage.setItem(`erdmp-${roadmapId}`, "1");
      amplitude.setUserId(`appuser-${appUserId}`);
      return appUserId;
    });
  }
}
