import { ParentMessenger } from "./communication/parentMessenger";
import { MessageType } from "./communication/types";
import {
  IFRAME_ROUTE_KEY,
  STORAGE_KEY_COMPANY,
  STORAGE_KEY_EMAIL,
  STORAGE_KEY_FIRST_NAME,
  STORAGE_KEY_LAST_NAME,
} from "./constant";
import { Data } from "./data";
import { i18next } from "./i18n";

(async function () {
  window.roadmap = window.roadmap || {};
  let iframe: HTMLIFrameElement | null = null;
  let messageElement: HTMLDivElement | null = null;
  const messenger = new ParentMessenger();
  const data = new Data();

  const roadmap: ILoadOptions = initRoadmapOptions();
  let roadmapData: IRoadmapData | null;

  const messageHandlers = {
    handleRouterChange: (hashPath: string) => {
      const url = new URL(window.location.href);
      const existingParams = new URLSearchParams(window.location.search);
      existingParams.set(IFRAME_ROUTE_KEY, hashPath);
      url.search = existingParams.toString();
      // FIX: can't replace path
      window.history.replaceState({}, "", url.toString());
    },

    handleRdmpLoaded: async () => {
      messageElement?.remove();

      const existingParams = new URLSearchParams(window.location.search);
      const pathname = existingParams.get(IFRAME_ROUTE_KEY) ?? "/";
      const cloneRm = Object.assign({}, roadmap);

      messenger.sendMessage(MessageType.RDMP_INIT, {
        pathname,
        roadmapData,
        options: cloneRm,
      });
      updateScrollPosition();
    },

    handleRdmpResize: (payload: number) => {
      updateIframeSize(payload);
    },

    handleSetUserInfo: (payload: IUserInfoFormData) => {
      roadmap.email = payload.email;
      roadmap.first = payload.first;
      roadmap.last = payload.last;
      syncLocalStorageData();
    },

    handleRdmpParentUrlResponse: () => {
      const currentUrl = window.location.href;

      messenger.sendMessage(MessageType.RDMP_PARENT_URL_RESPONSE, currentUrl);
    },
  };

  const container = getE(roadmap.containerId);
  if (container) {
    roadmapData = await loadRoadmapData(container);
    if (!roadmapData) {
      if (messageElement.textContent === i18next.t("loading...")) {
        messageElement.textContent = i18next.t("Roadmap not found");
      }
      return;
    }

    const versionRegex = /\s*\/\*\s*use-widget-version:\s*1\s*\*\//;
    if (
      roadmapData.roadmap?.css &&
      versionRegex.test(roadmapData.roadmap.css)
    ) {
      const script = document.querySelector("#public-roadmap");
      script?.remove();

      const newScript = document.createElement("script");
      newScript.id = "public-roadmap";
      newScript.type = "text/javascript";
      newScript.src = PUBLIC_PATH + "/roadmap.v1.js";
      newScript.setAttribute("data-id", roadmap.roadmapId);
      newScript.setAttribute("data-embedded", String(roadmap.isEmbedded));
      newScript.setAttribute("data-container", roadmap.containerId);
      newScript.setAttribute("data-email", roadmap.email || "");
      newScript.setAttribute("data-first", roadmap.first || "");
      newScript.setAttribute("data-last", roadmap.last || "");
      newScript.setAttribute("data-company", roadmap.company || "");
      newScript.setAttribute("data-revenue", roadmap.revenue || "");
      document.body.appendChild(newScript);
      return;
    }

    initIframe(container);
    initMessenger();
    initEventListeners();
    initWidgetIdentify();
  }
  syncLocalStorageData();

  function getE(id: string) {
    return document.getElementById(id);
  }

  function initRoadmapOptions(): ILoadOptions {
    const element = getE("public-roadmap");
    return {
      roadmapId: ROADMAP_ID || element.dataset.id,
      isEmbedded: element.dataset.embedded != "false",
      containerId: element.dataset.container,
      email: element.dataset.email,
      first: element.dataset.first,
      last: element.dataset.last,
      company: element.dataset.company,
      revenue: element.dataset.revenue,
      appUserId: null,
    };
  }

  async function loadRoadmapData(
    container: HTMLElement
  ): Promise<IRoadmapData | null> {
    const createMessageElement = (): HTMLDivElement => {
      const element = document.createElement("div");
      element.id = "roadmap-bootstraper__message";
      element.style.position = "absolute";
      element.style.top = "50%";
      element.style.left = "50%";
      element.style.transform = "translate(-50%, -50%)";
      element.style.zIndex = "1000";
      element.style.fontSize = "20px";
      element.style.textTransform = "capitalize";
      element.style.textAlign = "center";
      element.textContent = i18next.t("loading...");
      return element;
    };

    messageElement = createMessageElement();

    try {
      container.appendChild(messageElement);

      const roadmapData = await data.getRoadmap(roadmap.roadmapId);
      return roadmapData;
    } catch (error: any) {
      messageElement.textContent = error.message || error.error;
    }
  }

  function syncLocalStorageData() {
    const fields = {
      email: STORAGE_KEY_EMAIL,
      first: STORAGE_KEY_FIRST_NAME,
      last: STORAGE_KEY_LAST_NAME,
      company: STORAGE_KEY_COMPANY,
    };

    Object.entries(fields).forEach(([key, storageKey]) => {
      if (roadmap[key]) {
        localStorage.setItem(storageKey, roadmap[key] || "");
      }
    });

    Object.entries(fields).forEach(([key, storageKey]) => {
      const value = localStorage.getItem(storageKey);
      if (!roadmap[key] && value) {
        roadmap[key] = value;
      }
    });
  }

  function initIframe(container: HTMLElement) {
    iframe = document.createElement("iframe");
    iframe.id = "roadmap-bootstraper";
    iframe.style.border = "0";
    updateIframeSize();
    iframe.src = PUBLIC_PATH + "/roadmap.html";
    container.appendChild(iframe);
  }

  function initMessenger() {
    messenger.init(iframe.contentWindow, "*");
    messenger.addListener(
      MessageType.ROUTER_CHANGE,
      messageHandlers.handleRouterChange
    );
    messenger.addListener(
      MessageType.RDMP_LOADED,
      messageHandlers.handleRdmpLoaded
    );
    messenger.addListener(
      MessageType.RDMP_RESIZE,
      messageHandlers.handleRdmpResize
    );
    messenger.addListener(
      MessageType.SET_USER_INFO,
      messageHandlers.handleSetUserInfo
    );
    messenger.addListener(
      MessageType.RDMP_PARENT_URL_RESPONSE,
      messageHandlers.handleRdmpParentUrlResponse
    );
  }

  function initEventListeners() {
    window.onresize = () => updateIframeSize();
    window.onscroll = () => updateScrollPosition();
  }

  function initWidgetIdentify() {
    if (roadmap.roadmapId && roadmap.email) {
      let data = getE("public-roadmap").dataset;
      const fields = ["email", "first", "last", "company", "revenue"];

      fields.forEach((field) => {
        if (roadmap[field]) {
          data[field] = roadmap[field];
        }
      });

      widgetIdentify(roadmap.roadmapId, data);
    }
  }

  function updateIframeSize(height?: number) {
    if (!iframe || !container) return;

    if (container.offsetWidth === 0 && container.parentElement) {
      container.parentElement.style.width = "100%";
    }

    iframe.height = container.style.height
      ? container.getBoundingClientRect().height.toString()
      : "1080";

    // if (height) {
    //   console.log(`height`, height);
    //   console.log(`window.innerHeight`, window.innerHeight);
    //   iframe.height = Math.max(
    //     height,
    //     !roadmap.isEmbedded
    //       ? container.parentElement.getBoundingClientRect().height
    //       : 750,
    //     750
    //   ).toString();
    //   iframe.style.height = iframe.height;
    // }

    iframe.style.height = iframe.height;

    iframe.width = container.offsetWidth.toString();
    iframe.style.width = iframe.width;
  }

  function updateScrollPosition() {
    const newY = Math.max(
      window.scrollY -
        (window.pageYOffset + iframe.getBoundingClientRect().top),
      0
    );
    messenger.sendMessage(MessageType.RDMP_SCROLL, newY);
  }

  async function widgetIdentify(roadmapId, _data) {
    try {
      await data.widgetidentify(roadmapId, _data);
    } catch (error) {
      console.error(error);
    }
  }
})();
