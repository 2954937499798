import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { register } from "timeago.js";
import timeagoDe from "timeago.js/lib/lang/de";
import timeagoEs from "timeago.js/lib/lang/es";
import timeagoFr from "timeago.js/lib/lang/fr";
import timeagoNl from "timeago.js/lib/lang/nl";
import timeagoPl from "timeago.js/lib/lang/pl";
import timeagoPt from "timeago.js/lib/lang/pt_BR";

import translationDe from "../locales/de/roadmap.json";
import translationEn from "../locales/en/roadmap.json";
import translationEs from "../locales/es/roadmap.json";
import translationFr from "../locales/fr/roadmap.json";
import translationNl from "../locales/nl/roadmap.json";
import translationPl from "../locales/pl/roadmap.json";
import translationPt from "../locales/pt/roadmap.json";

register("de", timeagoDe);
register("es", timeagoEs);
register("fr", timeagoFr);
register("nl", timeagoNl);
register("pl", timeagoPl);
register("pt", timeagoPt);

const resources = {
  de: {
    translation: translationDe,
  },
  en: {
    translation: translationEn,
  },
  es: {
    translation: translationEs,
  },
  fr: {
    translation: translationFr,
  },
  nl: {
    translation: translationNl,
  },
  pl: {
    translation: translationPl,
  },
  pt: {
    translation: translationPt,
  },
};

i18next.use(initReactI18next);

i18next.init(
  {
    debug: false,
    resources,
    lng: "en",
    fallbackLng: "en",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  },
  (err, t) => {
    if (err) {
      console.error(
        `error while initialising i18next ::: ${JSON.stringify(err)}`
      );
    }
  }
);

export { i18next };
