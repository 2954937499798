import {
  IMessage,
  IMessagePayloadTypes,
  MessageHandler,
  MessageType,
} from "./types";

export class ParentMessenger {
  private iframeWindow?: Window;
  private targetOrigin: string = "*";
  private initTimer?: number;
  private handlers: Map<keyof IMessagePayloadTypes, MessageHandler<any>> =
    new Map();
  private prevRouterHash: string | null = null;

  init(iframeWindow: Window, targetOrigin: string = "*"): void {
    if (!iframeWindow) {
      throw new Error("iframeWindow is required");
    }
    this.iframeWindow = iframeWindow;
    this.targetOrigin = targetOrigin;

    window.addEventListener("message", this.handleMessage);

    // this.startRouteSync();
  }

  addListener<K extends keyof IMessagePayloadTypes>(
    type: K,
    handler: MessageHandler<IMessagePayloadTypes[K]>
  ): void {
    this.handlers.set(type, handler);
  }

  sendMessage<K extends keyof IMessagePayloadTypes>(
    type: K,
    payload: IMessagePayloadTypes[K]
  ): void {
    if (!this.iframeWindow) {
      console.error("Iframe window not initialized");
      return;
    }
    this.iframeWindow.postMessage({ type, payload }, this.targetOrigin);
  }

  private handleMessage = (event: MessageEvent<IMessage>) => {
    const { type, payload } = event.data;
    const handler = this.handlers.get(type);

    if (handler) {
      handler(payload);
    }
  };

  destroy(): void {
    // this.clearRouteSync();
    window.removeEventListener("message", this.handleMessage);
  }

  // private startRouteSync(): void {
  //   const existingParams = new URLSearchParams(window.location.search);
  //   const hash = existingParams.get(IFRAME_ROUTE_KEY);

  //   this.initTimer = window.setInterval(() => {
  //     this.sendMessage(MessageType.ROUTER_INIT, hash);
  //   }, 200);
  // }

  private handlePopState = () => {
    if (window.location.hash !== this.prevRouterHash) {
      this.sendMessage(MessageType.ROUTER_CHANGE, window.location.hash);
      this.prevRouterHash = window.location.hash;
    }
  };

  // private clearRouteSync(): void {
  //   if (this.initTimer) {
  //     clearInterval(this.initTimer);
  //     this.initTimer = undefined;
  //   }
  // }
}
